import React, { useEffect, useRef } from 'react';

const P5LoadingGame = () => {
  const canvasRef = useRef(null);
  const p5Instance = useRef(null);
  const isInitialized = useRef(false);

  useEffect(() => {
    if (isInitialized.current) return;
    isInitialized.current = true;
    
    console.log('Mounting P5LoadingGame');
    
    if (canvasRef.current) {
      const existingCanvases = canvasRef.current.getElementsByClassName('p5Canvas');
      Array.from(existingCanvases).forEach(canvas => canvas.remove());
    }
    
    import('p5').then((p5Module) => {
      const P5 = p5Module.default;
      console.log('P5 loaded, creating sketch');

      const sketch = (p) => {
        let ball = {
          x: 0,
          y: 0,
          speedX: 5,
          speedY: 3,
          size: 20
        };

        p.setup = () => {
          console.log('Setting up P5 sketch');
          const canvas = p.createCanvas(600, 400);
          canvas.parent(canvasRef.current);
          ball.x = p.width / 2;
          ball.y = p.height / 2;
          p.background(240);
        };

        p.draw = () => {
          p.background(240, 10);

          p.fill(0);
          p.textAlign(p.CENTER, p.CENTER);
          p.textSize(16);
          p.text('Generating your workflow...', p.width/2, 30);
          p.text('This might take a minute', p.width/2, 50);
          
          ball.x += ball.speedX;
          ball.y += ball.speedY;

          if (ball.x > p.width - ball.size/2 || ball.x < ball.size/2) {
            ball.speedX *= -1;
          }
          if (ball.y > p.height - ball.size/2 || ball.y < ball.size/2) {
            ball.speedY *= -1;
          }

          p.fill(41, 128, 185);
          p.noStroke();
          p.ellipse(ball.x, ball.y, ball.size);

          if (p.mouseIsPressed) {
            let dx = p.mouseX - ball.x;
            let dy = p.mouseY - ball.y;
            ball.x += dx * 0.05;
            ball.y += dy * 0.05;
          }
        };
      };

      if (!p5Instance.current) {
        p5Instance.current = new P5(sketch);
      }
    });

    return () => {
      if (p5Instance.current) {
        console.log('Cleaning up P5 instance');
        p5Instance.current.remove();
        p5Instance.current = null;
      }
      isInitialized.current = false;
    };
  }, []);

  return (
    <div 
      ref={canvasRef}
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: '#f0f0f0'
      }}
    />
  );
};

export default P5LoadingGame;