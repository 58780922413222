import React from 'react';

const HamburgerMenu = ({ onClick, isOpen }) => (
  <button
    onClick={onClick}
    className={`hamburger-button ${isOpen ? 'open' : ''}`}
  >
    <span className="hamburger-line" />
    <span className="hamburger-line" />
    <span className="hamburger-line" />
  </button>
);

export default HamburgerMenu;