import React, { useState, useCallback, useRef, useEffect, memo, useMemo, useLayoutEffect, useContext, createContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import ReactFlow, { 
  Controls, 
  Background,
  applyNodeChanges,
  applyEdgeChanges,
  addEdge,
  ReactFlowProvider,
  useReactFlow,
  Handle,
  Position,
  NodeToolbar,
  MarkerType,
  NodeResizeControl,
  BaseEdge,
  EdgeLabelRenderer,
  getBezierPath
} from 'reactflow';
import { v4 as uuidv4 } from 'uuid';
import { saveAs } from 'file-saver';
import dagre from 'dagre';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ImageDrop } from 'quill-image-drop-module';
import ImageResize from 'quill-image-resize-module-react';
import Quill from 'quill';
import 'reactflow/dist/style.css';
import './SideDrawer.css';
import './ChatButton.css';
import ReactMarkdown from 'react-markdown';
import MDEditor from '@uiw/react-md-editor';
import ConfirmationModal from './components/ConfirmationModal';
import { SketchPicker } from 'react-color';
import { CSSProperties } from 'react';
import { CSSTransition } from 'react-transition-group';
import P5LoadingGame from './P5LoadingGame';
import HamburgerMenu from './components/HamburgerMenu';
import './App.css';

Quill.register('modules/imageDrop', ImageDrop);
Quill.register('modules/imageResize', ImageResize);

const shortUuid = () => {
  return uuidv4().slice(0, 6);
};

const nodeWidth = 172;
const nodeHeight = 36;

const controlStyle = {
  background: 'transparent',
  border: 'none',
};

// Add this new component for the loading spinner
const LoadingSpinner = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      animation: 'spin 1s linear infinite',
    }}
  >
    <style>
      {`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}
    </style>
    <circle
      cx="12"
      cy="12"
      r="10"
      stroke="#000000"
      strokeWidth="2"
      fill="none"
      strokeDasharray="31.4 31.4"
    />
  </svg>
);

const PadlockIcon = ({ isLocked }) => {
  // Add console log to verify prop
  console.log('PadlockIcon isLocked:', isLocked);
  
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {isLocked ? (
        // Closed padlock with solid black color
        <path
          d="M 19 11 H 5 C 3.8954 11 3 11.8954 3 13 V 20 C 3 21.1046 3.8954 22 5 22 H 19 C 20.1046 22 21 21.1046 21 20 V 13 C 21 11.8954 20.1046 11 19 11 Z M 12 17 C 11.4477 17 11 16.5523 11 16 C 11 15.4477 11.4477 15 12 15 C 12.5523 15 13 15.4477 13 16 C 13 16.5523 12.5523 17 12 17 Z M 17 11 V 6 C 17 3.2386 14.7614 1 12 1 C 9.2386 1 7 3.2386 7 6 V 11"
          stroke="#000000"
          strokeWidth="2"
        />
      ) : (
        // Open padlock with lighter gray color
        <path
          d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11ZM12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16C13 16.5523 12.5523 17 12 17Z M7 11V6C7 3.23858 9.23858 1 12 1C14.7614 1 17 3.23858 17 6"
          stroke="#B0B0B0"
          strokeWidth="2"
        />
      )}
    </svg>
  );
};

const MorphingButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [textValue, setTextValue] = useState('');
  const [buttonRect, setButtonRect] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { fitView } = useReactFlow();

  // Get access to the context functions
  const { 
    setFlowData, 
    setWorkflowMetadata,
    handleNodeChange,
    handleNodeClick,
    handleNodeResize 
  } = useContext(FlowContext);

  const handleSubmit = async () => {
    if (!textValue.trim()) return;
    
    setIsLoading(true);

    try {
      const response = await fetch('/api/ai-workflow-connect', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message: textValue }),
        credentials: 'include'
      });

      const data = await response.json();
      if (!response.ok) throw new Error(data.error || 'Failed to generate workflow');

      const workflowData = JSON.parse(data.message);
      console.log('Workflow data received:', workflowData);

      // Set workflow metadata
      setWorkflowMetadata({
        title: workflowData.metadata.title || 'Generated Workflow',
        description: workflowData.metadata.description || 'AI-generated workflow'
      });

      // Process nodes with required properties
      const modifiedNodes = workflowData.nodes.map(node => ({
        ...node,
        type: 'custom',
        position: node.position,
        width: node.width || 172, // Default width
        height: node.height || 36, // Default height
        data: {
          ...node.data,
          label: node.data.label || 'Untitled',
          description: node.data.description || 'No description available.',
          type: node.data.type || 'markdown',
          isAI: node.data.isAI || false,
          isLocked: node.data.isLocked || false,
          backgroundColor: node.data.backgroundColor || '#FFFFFF',
          fontColor: node.data.fontColor || '#000000',
          borderStyle: node.data.borderStyle || 'solid',
          onChange: handleNodeChange,
          onNodeClick: handleNodeClick,
          onResize: handleNodeResize
        }
      }));

      // Process edges with required properties
      const modifiedEdges = workflowData.edges.map(edge => ({
        ...edge,
        type: 'custom',
        style: {
          stroke: edge.style?.stroke || '#000000',
          strokeWidth: edge.style?.strokeWidth || 1,
          strokeDasharray: edge.style?.strokeDasharray || null
        },
        markerEnd: {
          type: MarkerType.ArrowClosed,
          width: 20,
          height: 20,
          color: edge.markerEnd?.color || '#000000'
        }
      }));

      setFlowData({ nodes: modifiedNodes, edges: modifiedEdges });

      // Fit view after a short delay to ensure nodes are rendered
      setTimeout(() => {
        fitView({ padding: 0.2 });
      }, 100);

      setIsModalOpen(false);
      setTextValue('');
    } catch (error) {
      console.error('Error generating workflow:', error);
      alert('Failed to generate workflow. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleButtonClick = (event) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setButtonRect(rect);
    setIsModalOpen(true);
  };

  return (
    <>
      <CSSTransition
        in={isModalOpen}
        timeout={300}
        classNames="morph"
        unmountOnExit
      >
        <div 
          className="modal-overlay"
          style={{
            '--button-left': buttonRect ? `${buttonRect.left}px` : '50%',
            '--button-top': buttonRect ? `${buttonRect.top}px` : '50%',
            '--button-width': buttonRect ? `${buttonRect.width}px` : 'auto',
            '--button-height': buttonRect ? `${buttonRect.height}px` : 'auto',
          }}
        >
          <div className="morphing-modal">
            {isLoading ? (
              <div style={{
                width: '600px',
                height: '100%',  // Changed from 400px to 100%
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#ffffff'
              }}>
                <P5LoadingGame />
              </div>
            ) : (
              <>
                <h2>Enter a workflow prompt...</h2>
                <textarea
                  value={textValue}
                  onChange={(e) => setTextValue(e.target.value)}
                  placeholder="Describe the workflow you want to create..."
                  disabled={isLoading}
                />
                <div className="modal-buttons">
                  <button 
                    onClick={handleSubmit}
                    disabled={isLoading}
                  >
                    {isLoading ? 'Generating...' : 'Generate Workflow'}
                  </button>
                  <button 
                    onClick={() => setIsModalOpen(false)}
                    disabled={isLoading}
                  >
                    Cancel
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </CSSTransition>

      {!isModalOpen && (
        <button
          className="morphing-button"
          onClick={handleButtonClick}
          style={{
            maxWidth: '90%',           // Limit width on small screens
            width: 'min(600px, 90vw)', // Use the smaller of 600px or 90% viewport width
            margin: '0 auto',          // Center the button
            whiteSpace: 'nowrap',      // Prevent text from wrapping
            overflow: 'hidden',        // Hide any overflow
            textOverflow: 'ellipsis',  // Show ellipsis for overflow text
          }}
        >
          Enter a workflow prompt...
        </button>
      )}
    </>
  );
};

const CustomNode = memo(({ data, id, isConnectable }) => {
  // Add deleteSelectedNode to the destructured props from data
  const { onNodeClick, onToggleColorPicker, onToggleFontColorPicker, onToggleBorderStyle, deleteSelectedNode, onLockChange } = data;
  const [label, setLabel] = useState(data.label);
  const nodeRef = useRef(null);
  const textRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 172, height: 36 });
  const resizeTimeoutRef = useRef(null);
  const { getNode, setNodes } = useReactFlow();

  useEffect(() => {
    setLabel(data.label);
  }, [data.label]);

  // Add useEffect to update minimum height when label or width changes
  useEffect(() => {
    if (textRef.current) {
      // Clear any existing timeout
      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }

      // Set a new timeout
      resizeTimeoutRef.current = setTimeout(() => {
        const textHeight = textRef.current.scrollHeight;
        const minHeight = textHeight + 20; // Add padding
        
        if (dimensions.height < minHeight) {
          setDimensions(prev => ({ ...prev, height: minHeight }));
          if (data.onResize) {
            data.onResize(id, dimensions.width, minHeight);
          }
        }
      }, 0);
    }

    // Cleanup
    return () => {
      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }
    };
  }, [label, dimensions.width, id, data.onResize]);

  const handleClick = (event) => {
    event.stopPropagation();
    data.onNodeClick(id, label, data.description, data.type);
  };

  // Modify onResize to use debouncing
  const onResize = useCallback((evt, { width, height }) => {
    if (resizeTimeoutRef.current) {
      clearTimeout(resizeTimeoutRef.current);
    }

    resizeTimeoutRef.current = setTimeout(() => {
      if (textRef.current) {
        const textHeight = textRef.current.scrollHeight;
        const minHeight = textHeight + 20; // Add padding
        
        // Ensure height is not less than minimum required
        const newHeight = Math.max(height, minHeight);
        
        setDimensions({ width, height: newHeight });
        if (data.onResize) {
          data.onResize(id, width, newHeight);
        }
      }
    }, 0);
  }, [id, data.onResize]);

  const toggleColorPicker = (event) => {
    event.stopPropagation();
    data.onToggleColorPicker(id);
  };

  const toggleFontColorPicker = (event) => {
    event.stopPropagation();
    data.onToggleFontColorPicker(id);
  };

  const backgroundColor = data.isColorPickerActive
    ? data.backgroundColor
    : data.isSelected
    ? '#FFFACD'
    : data.backgroundColor || '#FFFFFF';

  const fontColor = data.fontColor || '#000000';

  const isLoading = data.isAI && data.isLoading;

  const handleToggleBorderStyle = useCallback((event) => {
    event.stopPropagation();
    data.onToggleBorderStyle(id);
  }, [id, data.onToggleBorderStyle]);

  const handleLockToggle = useCallback((event) => {
    event.stopPropagation();
    if (data.onLockChange) {
      // Add console log to verify callback
      console.log('Toggle lock from:', data.isLocked, 'to:', !data.isLocked);
      data.onLockChange(id, !data.isLocked);
    }
  }, [id, data.onLockChange, data.isLocked]);

  // Add console log to verify data
  console.log('Node data:', id, data.isLocked);

  return (
    <div style={{ 
      position: 'relative',
      width: `${dimensions.width}px`,
      height: `${dimensions.height + (data.isSelected ? 40 : 0)}px`, // Add extra space when selected
    }}>
      <div
        ref={nodeRef}
        style={{
          border: `1px ${data.borderStyle || 'solid'} ${data.isSelected ? 'black' : '#ddd'}`,
          borderRadius: '5px',
          background: backgroundColor,
          width: '100%',
          height: `${dimensions.height}px`,
          position: 'absolute',
          top: 0,
          left: 0,
          overflow: 'visible',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '10px',
          boxSizing: 'border-box',
          boxShadow: data.isSelected ? '2px 2px 5px rgba(0, 0, 0, 0.3)' : 'none',
          transition: 'border 0.3s, box-shadow 0.3s',
          color: fontColor,
        }}
        onClick={handleClick}
      >
        <Handle
          type="target"
          position={Position.Left}
          isConnectable={isConnectable}
          style={{ left: -4, background: '#555', zIndex: 1000 }}
        />
        {isLoading ? (
          <div style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}>
            <LoadingSpinner />
          </div>
        ) : (
          <div 
            ref={textRef}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
              width: '90%',
              minHeight: '1em',
              wordBreak: 'break-word',
              hyphens: 'auto',
            }}
          >
            <strong>{label}</strong>
          </div>
        )}
        <Handle
          type="source"
          position={Position.Right}
          isConnectable={isConnectable}
          style={{ right: -4, background: '#555', zIndex: 1000 }}
        />

        {data.isAI && (
          <div
            style={{
            position: 'absolute',
            bottom: 2,
            left: 2,
            cursor: 'pointer',
            zIndex: 1000,
            opacity: data.isSelected ? 1 : 0.3, // More visible when selected
            transition: 'opacity 0.2s',
          }}
          onClick={handleLockToggle}
        >
          <PadlockIcon isLocked={data.isLocked} />
          </div>
        )}

        <NodeResizeControl
          minWidth={100}
          minHeight={36}
          isVisible={data.isSelected}
          onResize={onResize}
          style={{ background: 'transparent', border: 'none' }}
        >
          <div style={{ position: 'absolute', right: 2, bottom: -5, cursor: 'nwse-resize' }}>
            <ResizeIcon />
          </div>
        </NodeResizeControl>
      </div>
      {data.isSelected && (
        <div
          style={{
            position: 'absolute',
            top: `${dimensions.height}px`, // Position relative to node height
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1000,
            display: 'flex',
            gap: '5px',
            padding: '5px',
            pointerEvents: 'auto',
          }}
        >
          <button 
            onClick={toggleColorPicker}
            className="color-wheel-icon"
            style={{
              width: '20px',
              height: '20px',
              padding: 0,
              backgroundColor: 'white',
              border: '1px solid #ddd',
              borderRadius: '50%',
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1001,
            }}
          >
            <ColorWheelIcon />
          </button>
          <button 
            onClick={toggleFontColorPicker}
            className="font-color-icon"
            style={{
              width: '20px',
              height: '20px',
              padding: 0,
              backgroundColor: 'white',
              border: '1px solid #ddd',
              borderRadius: '50%',
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '14px',
              fontWeight: 'bold',
              textDecoration: 'underline',
              zIndex: 1001,
            }}
          >
            A
          </button>
          <button 
            onClick={(event) => {
              event.stopPropagation();
              data.onToggleBorderStyle(id);
            }}
            className="border-style-icon"
            style={{
              width: '19px',
              height: '19px',
              padding: 0,
              backgroundColor: 'white',
              border: '1px dashed black',
              borderRadius: '50%',
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1001,
            }}
          />
          <button 
            onClick={deleteSelectedNode}
            className="delete-node-icon"
            style={{
              width: '19px',
              height: '19px',
              padding: 0,
              backgroundColor: 'white',
              border: '1px solid #FF0000',
              borderRadius: '50%',
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#FF0000',
              fontSize: '14px',
              fontWeight: 'bold',
              zIndex: 1001,
            }}
          >
            ×
          </button>
        </div>
      )}
    </div>
  );
});

function ResizeIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="13" cy="4" r="0.6" fill="#C0C0C0" />
      <circle cx="10" cy="7" r="0.6" fill="#C0C0C0" />
      <circle cx="13" cy="7" r="0.6" fill="#C0C0C0" />
      <circle cx="7" cy="10" r="0.6" fill="#C0C0C0" />
      <circle cx="10" cy="10" r="0.6" fill="#C0C0C0" />
      <circle cx="13" cy="10" r="0.6" fill="#C0C0C0" />
    </svg>
  );
}

const nodeTypes = {
  custom: CustomNode,
};

// Display Workflow metadata (i.e., title and description) in a modal
const WorkflowInfoModal = ({ isOpen, onClose, title, description, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(title);
  const [editedDescription, setEditedDescription] = useState(description);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [onConfirmAction, setOnConfirmAction] = useState(() => () => {});

  if (!isOpen) return null;

  const handleEdit = () => {
    setIsEditing(true);
    setEditedTitle(title);
    setEditedDescription(description);
  };

  const handleSave = () => {
    onSave(editedTitle, editedDescription);
    setIsEditing(false);
  };

  const handleClose = () => {
    if (isEditing && (editedTitle !== title || editedDescription !== description)) {
      setConfirmationMessage("Discard your changes?");
      setOnConfirmAction(() => () => {
        setIsEditing(false);
        onClose();
        setIsConfirmationOpen(false);
      });
      setIsConfirmationOpen(true);
    } else {
      onClose();
    }
  };

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 2000,
    }}>
      <div style={{
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '5px',
        maxWidth: '500px',
        width: '90%',
        position: 'relative',
      }}>
        {isEditing ? (
          <button onClick={handleSave} style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            background: 'none',
            border: 'none',
            fontSize: '20px',
            cursor: 'pointer',
          }}>
            💾
          </button>
        ) : (
          <button onClick={handleEdit} style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            background: 'none',
            border: 'none',
            fontSize: '20px',
            cursor: 'pointer',
          }}>
            ✎
          </button>
        )}
        {isEditing ? (
          <>
            <input
              value={editedTitle}
              onChange={(e) => setEditedTitle(e.target.value)}
              style={{
                fontSize: '24px',
                fontWeight: 'bold',
                width: '100%',
                marginTop: '20px',
                marginBottom: '10px',
              }}
            />
            <textarea
              value={editedDescription}
              onChange={(e) => setEditedDescription(e.target.value)}
              style={{
                width: '100%',
                minHeight: '100px',
              }}
            />
          </>
        ) : (
          <>
            <h2>{title}</h2>
            <p>{description}</p>
          </>
        )}
        <button onClick={handleClose}>Close</button>
      </div>
      <ConfirmationModal
        isOpen={isConfirmationOpen}
        message={confirmationMessage}
        onConfirm={onConfirmAction}
        onCancel={() => setIsConfirmationOpen(false)}
      />
    </div>
  );
};

// Create a context for flow-related functions
const FlowContext = createContext(null);

function FlowChart({ accessToken, notionAuthUrl }) {
  const [searchParams] = useSearchParams();
  const [flowData, setFlowData] = useState({ nodes: [], edges: [] });
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [workflowMetadata, setWorkflowMetadata] = useState({ title: '', description: '' });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedNodePosition, setSelectedNodePosition] = useState({ x: 0, y: 0 });
  const { fitView, project } = useReactFlow();
  const reactFlowWrapper = useRef(null);
  const [isAddNodeMenuOpen, setIsAddNodeMenuOpen] = useState(false);
  const [notionPages, setNotionPages] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const addNodeMenuRef = useRef(null);
  const [lastNodePosition, setLastNodePosition] = useState(null);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [onConfirmAction, setOnConfirmAction] = useState(() => () => {});
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [recentColors, setRecentColors] = useState(['#FFFFFF']); // Default white color
  const [colorPickerNode, setColorPickerNode] = useState(null);
  const colorPickerRef = useRef(null);
  const [colorPickerPosition, setColorPickerPosition] = useState({ x: 0, y: 0 });
  const [selectedEdge, setSelectedEdge] = useState(null);
  const [fontColorPickerNode, setFontColorPickerNode] = useState(null);
  const [fontColorPickerPosition, setFontColorPickerPosition] = useState({ x: 0, y: 0 });
  const fontColorPickerRef = useRef(null);
  const [lockedNodes, setLockedNodes] = useState(new Set());
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // Add this state to track if the add node sub-menu is open
  const [isAddNodeSubMenuOpen, setIsAddNodeSubMenuOpen] = useState(false);
  // Add this near your other state declarations in FlowChart
  const submenuRef = useRef(null);
  // Add these state declarations at the top of your FlowChart component
  const [menuState, setMenuState] = useState({
    isOpen: false,
    type: null // 'desktop' or 'mobile'
  });
  const menuRef = useRef(null);
  // Add this new state for Notion import modal
  const [isNotionModalOpen, setIsNotionModalOpen] = useState(false);

  const handleSaveWorkflowMetadata = (newTitle, newDescription) => {
    setWorkflowMetadata({ title: newTitle, description: newDescription });
  };

  const handleNodeChange = useCallback((id, newData) => {
    setFlowData((prev) => ({
      ...prev,
      nodes: prev.nodes.map((node) =>
        node.id === id
          ? {
              ...node,
              data: {
                ...node.data,
                ...newData,
                label: newData.label || node.data.label,
                isSelected: true,
                backgroundColor: newData.backgroundColor || node.data.backgroundColor,
              },
            }
          : {
            ...node,
            data: {
              ...node.data,
              isSelected: false
            }
          }
      ),
    }));

    setSelectedNode((prevSelectedNode) => {
      if (prevSelectedNode && prevSelectedNode.id === id) {
        return { ...prevSelectedNode, ...newData };
      }
      return prevSelectedNode;
    });
  }, []);

  const handleColorChange = useCallback((color) => {
    if (colorPickerNode) {
      setFlowData(prev => ({
        ...prev,
        nodes: prev.nodes.map(node => 
          node.id === colorPickerNode.id
            ? {
                ...node,
                data: {
                  ...node.data,
                  backgroundColor: color.hex,
                  isColorPickerActive: true,
                }
              }
            : node
        )
      }));

      // Update recent colors
      setRecentColors(prevColors => {
        const newColors = [color.hex, ...prevColors.filter(c => c !== color.hex)];
        return newColors.slice(0, 5);
      });
    }
  }, [colorPickerNode]);

  const handleColorChangeComplete = useCallback((color) => {
    if (colorPickerNode) {
      setFlowData(prev => ({
        ...prev,
        nodes: prev.nodes.map(node => 
          node.id === colorPickerNode.id
            ? {
                ...node,
                data: {
                  ...node.data,
                  backgroundColor: color.hex,
                  // Keep isColorPickerActive true
                }
              }
            : node
        )
      }));
      // Don't close the color picker here
    }
  }, [colorPickerNode]);

  const handleToggleColorPicker = useCallback((nodeId) => {
    setColorPickerNode(prev => {
      if (prev?.id === nodeId) {
        // Closing the color picker
        setFlowData(prevData => ({
          ...prevData,
          nodes: prevData.nodes.map(node => 
            node.id === nodeId
              ? {
                  ...node,
                  data: {
                    ...node.data,
                    isColorPickerActive: false,
                  }
                }
              : node
          )
        }));
        return null;
      } else {
        // Opening the color picker
        const node = flowData.nodes.find(n => n.id === nodeId);
        setFlowData(prevData => ({
          ...prevData,
          nodes: prevData.nodes.map(n => 
            n.id === nodeId
              ? {
                  ...n,
                  data: {
                    ...n.data,
                    isColorPickerActive: true,
                    isFontColorPickerActive: false, // Close font color picker
                  }
                }
              : {
                  ...n,
                  data: {
                    ...n.data,
                    isColorPickerActive: false,
                    isFontColorPickerActive: false,
                  }
                }
          )
        }));
        setFontColorPickerNode(null); // Dismiss font color picker
        return { id: nodeId };
      }
    });
  }, [flowData.nodes]);

  const handleFontColorChange = useCallback((color) => {
    if (fontColorPickerNode) {
      setFlowData(prev => ({
        ...prev,
        nodes: prev.nodes.map(node => 
          node.id === fontColorPickerNode.id
            ? {
                ...node,
                data: {
                  ...node.data,
                  fontColor: color.hex,
                  isFontColorPickerActive: true,
                }
              }
            : node
        )
      }));

      // Update recent colors (you can create a separate state for font colors if needed)
      setRecentColors(prevColors => {
        const newColors = [color.hex, ...prevColors.filter(c => c !== color.hex)];
        return newColors.slice(0, 5);
      });
    }
  }, [fontColorPickerNode]);

  const handleToggleFontColorPicker = useCallback((nodeId) => {
    setFontColorPickerNode(prev => {
      if (prev?.id === nodeId) {
        // Closing the color picker
        setFlowData(prevData => ({
          ...prevData,
          nodes: prevData.nodes.map(node => 
            node.id === nodeId
              ? {
                  ...node,
                  data: {
                    ...node.data,
                    isFontColorPickerActive: false,
                  }
                }
              : node
          )
        }));
        return null;
      } else {
        // Opening the color picker
        const node = flowData.nodes.find(n => n.id === nodeId);
        setFlowData(prevData => ({
          ...prevData,
          nodes: prevData.nodes.map(n => 
            n.id === nodeId
              ? {
                  ...n,
                  data: {
                    ...n.data,
                    isFontColorPickerActive: true,
                    isColorPickerActive: false, // Close background color picker
                  }
                }
              : {
                  ...n,
                  data: {
                    ...n.data,
                    isFontColorPickerActive: false,
                    isColorPickerActive: false,
                  }
                }
          )
        }));
        setColorPickerNode(null); // Dismiss background color picker
        return { id: nodeId };
      }
    });
  }, [flowData.nodes]);

  const handleToggleBorderStyle = useCallback((nodeId) => {
    // Dismiss any open color pickers
    setColorPickerNode(null);
    setFontColorPickerNode(null);

    setFlowData(prev => ({
      ...prev,
      nodes: prev.nodes.map(node => 
        node.id === nodeId
          ? {
              ...node,
              data: {
                ...node.data,
                borderStyle: node.data.borderStyle === 'dashed' ? 'solid' : 'dashed',
              }
            }
          : node
      )
    }));
  }, []);

  useEffect(() => {
    if (colorPickerNode) {
      // Use setTimeout to ensure the DOM has updated
      setTimeout(() => {
        const nodeElement = document.querySelector(`[data-id="${colorPickerNode.id}"]`);
        if (nodeElement) {
          const colorWheelIcon = nodeElement.querySelector('.color-wheel-icon');
          if (colorWheelIcon) {
            const iconRect = colorWheelIcon.getBoundingClientRect();
            setColorPickerPosition({
              x: iconRect.left + iconRect.width / 2,
              y: iconRect.bottom
            });
          }
        }
      }, 0);
    }
  }, [colorPickerNode]);

  useEffect(() => {
    if (fontColorPickerNode) {
      // Use setTimeout to ensure the DOM has updated
      setTimeout(() => {
        const nodeElement = document.querySelector(`[data-id="${fontColorPickerNode.id}"]`);
        if (nodeElement) {
          const fontColorIcon = nodeElement.querySelector('.font-color-icon');
          if (fontColorIcon) {
            const iconRect = fontColorIcon.getBoundingClientRect();
            setFontColorPickerPosition({
              x: iconRect.left + iconRect.width / 2,
              y: iconRect.bottom
            });
          }
        }
      }, 0);
    }
  }, [fontColorPickerNode]);

  const handleClickOutside = useCallback((event) => {
    if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
      setColorPickerNode(null);
      setFlowData(prev => ({
        ...prev,
        nodes: prev.nodes.map(node => ({
          ...node,
          data: {
            ...node.data,
            isColorPickerActive: false,
          }
        }))
      }));
    }
    if (fontColorPickerRef.current && !fontColorPickerRef.current.contains(event.target)) {
      setFontColorPickerNode(null);
      setFlowData(prev => ({
        ...prev,
        nodes: prev.nodes.map(node => ({
          ...node,
          data: {
            ...node.data,
            isFontColorPickerActive: false,
          }
        }))
      }));
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  const onNodesChange = useCallback((changes) => {
    setFlowData((prev) => {
      const newNodes = applyNodeChanges(changes, prev.nodes);
      
      // If the color picker is open, update its position
      if (colorPickerNode) {
        const updatedNode = newNodes.find(node => node.id === colorPickerNode.id);
        if (updatedNode) {
          setTimeout(() => {
            const nodeElement = document.querySelector(`[data-id="${updatedNode.id}"]`);
            if (nodeElement) {
              const colorWheelIcon = nodeElement.querySelector('.color-wheel-icon');
              if (colorWheelIcon) {
                const iconRect = colorWheelIcon.getBoundingClientRect();
                setColorPickerPosition({
                  x: iconRect.left + iconRect.width / 2,
                  y: iconRect.bottom
                });
              }
            }
          }, 0);
        }
      }
      
      // If the font color picker is open, update its position
      if (fontColorPickerNode) {
        const updatedNode = newNodes.find(node => node.id === fontColorPickerNode.id);
        if (updatedNode) {
          setTimeout(() => {
            const nodeElement = document.querySelector(`[data-id="${updatedNode.id}"]`);
            if (nodeElement) {
              const fontColorIcon = nodeElement.querySelector('.font-color-icon');
              if (fontColorIcon) {
                const iconRect = fontColorIcon.getBoundingClientRect();
                setFontColorPickerPosition({
                  x: iconRect.left + iconRect.width / 2,
                  y: iconRect.bottom
                });
              }
            }
          }, 0);
        }
      }
      
      return {
        ...prev,
        nodes: newNodes,
      };
    });
  }, [colorPickerNode, fontColorPickerNode]);

  const handleNodeResize = useCallback((id, width, height) => {
    setFlowData((prev) => ({
      ...prev,
      nodes: prev.nodes.map((node) =>
        node.id === id
          ? { ...node, width, height }
          : node
      ),
    }));
  }, []);

  const onEdgesChange = useCallback((changes) => {
    setFlowData((prev) => ({
      ...prev,
      edges: applyEdgeChanges(changes, prev.edges),
    }));
  }, []);

  const onConnect = useCallback((params) => {
    setFlowData((prev) => {
      // Check if an edge already exists between the source and target
      const edgeExists = prev.edges.some(
        edge => edge.source === params.source && edge.target === params.target
      );

      if (edgeExists) {
        console.log("An edge already exists between these nodes.");
        return prev;
      }

      const newEdge = {
        id: shortUuid(),
        source: params.source,
        target: params.target,
        markerEnd: { 
          type: MarkerType.ArrowClosed,
          width: 20,
          height: 20,
          color: '#000000',
        },
        style: { stroke: '#000000', strokeWidth: 1 },
      };

      // Find the source and target nodes
      const sourceNode = prev.nodes.find(node => node.id === params.source);
      const targetNode = prev.nodes.find(node => node.id === params.target);

      // Add the edge immediately
      const newState = {
        ...prev,
        edges: [...prev.edges, newEdge],
      };

      // Check if the target node is an AI node AND is not locked
      if (targetNode?.data.isAI && !targetNode.data.isLocked) {
        console.log(`Node ${params.source} Attached to AI ${params.target}`);
        
        // Update the target node to show loading state
        const updatedNodes = prev.nodes.map(node => 
          node.id === targetNode.id
            ? { ...node, data: { ...node.data, isLoading: true } }
            : node
        );

        // Prepare workflow data for API
        const workflowData = {
          sourceNodeId: params.source,
          targetNodeId: params.target,
          workflow: {
            nodes: updatedNodes.map(({ id, type, position, data }) => ({
              id,
              type,
              position,
              data: {
                label: data.label,
                description: data.description,
                type: data.type,
                isAI: data.isAI,
                isLocked: data.isLocked
              }
            })),
            edges: [...prev.edges, newEdge].map(({ id, source, target }) => ({
              id,
              source,
              target
            }))
          }
        };

        // Make API call to /api/ai-node-connect
        fetch('/api/ai-node-connect', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(workflowData),
          credentials: 'include'
        })
        .then(response => response.json())
        .then(data => {
          console.log('AI response received:', data);
          const aiResponse = JSON.parse(data.message);
          
          setFlowData(current => {
            const updatedNodes = current.nodes.map(node => 
              node.id === targetNode.id
                ? { ...node, data: { ...node.data, description: aiResponse.ai_answer, label: aiResponse.ai_gen_title, isLoading: false, isLocked: true } }
                : {
                    ...node,
                    data: {
                      ...node.data,
                      isSelected: false
                    }
                  }
            );

            // Set the AI node as selected
            const aiNode = updatedNodes.find(node => node.id === targetNode.id);
            setSelectedNode({
              id: targetNode.id,
              label: aiResponse.ai_gen_title,
              description: aiResponse.ai_answer,
              type: 'ai'
            });
            setDrawerOpen(true);

            return {
              ...current,
              nodes: updatedNodes
            };
          });
        })
        .catch(error => {
          console.error('Error calling AI node connect:', error);
          setFlowData(current => ({
            ...current,
            nodes: current.nodes.map(node => 
              node.id === targetNode.id
                ? { ...node, data: { ...node.data, isLoading: false } }
                : node
            )
          }));
        });

        return {
          ...prev,
          nodes: updatedNodes,
          edges: [...prev.edges, newEdge],
        };
      }

      return {
        ...prev,
        edges: [...prev.edges, newEdge],
      };
    });
  }, []);

  const handleNodeClick = useCallback((id, label, description, type) => {
    // Dismiss color picker if clicking on a different node
    if (colorPickerNode && colorPickerNode.id !== id) {
      setColorPickerNode(null);
    }

    // Dismiss font color picker if clicking on a different node
    if (fontColorPickerNode && fontColorPickerNode.id !== id) {
      setFontColorPickerNode(null);
    }

    setSelectedNode({ id, label, description, type });
    setDrawerOpen(true);
    // Update the nodes to reflect the new selection and deselect the edge
    setFlowData(prev => ({
      ...prev,
      nodes: prev.nodes.map(node => ({
        ...node,
        data: {
          ...node.data,
          isSelected: node.id === id,
        }
      })),
      // Deselect the edge
      edges: prev.edges.map(edge => ({
        ...edge,
        style: {
          ...edge.style,
          stroke: '#000000',
          strokeWidth: 1,
        },
        markerEnd: {
          ...edge.markerEnd,
          color: '#000000',
        },
        data: { ...edge.data, isSelected: false },
      }))
    }));
    setSelectedEdge(null);
  }, [colorPickerNode, fontColorPickerNode]);

  const onPaneClick = useCallback(() => {
    // Dismiss color picker when clicking on the canvas
    setColorPickerNode(null);
    // Dismiss font color picker when clicking on the canvas
    setFontColorPickerNode(null);
    
    // Deselect all nodes
    setFlowData(prev => ({
      ...prev,
      nodes: prev.nodes.map(node => ({
        ...node,
        data: {
          ...node.data,
          isSelected: false,
        }
      })),
      // Deselect the edge
      edges: prev.edges.map(edge => ({
        ...edge,
        style: {
          ...edge.style,
          stroke: '#000000',
          strokeWidth: 1,
        },
        markerEnd: {
          ...edge.markerEnd,
          color: '#000000',
        },
        data: { ...edge.data, isSelected: false },
      }))
    }));
    setSelectedNode(null);
    setSelectedEdge(null);
    setDrawerOpen(false);
  }, []);

  const handleEditNode = useCallback((id) => {
    // Open the side drawer for editing
    setSelectedNode(flowData.nodes.find(node => node.id === id));
    setDrawerOpen(true);
  }, [flowData.nodes]);

  const handleDeleteNode = useCallback((id) => {
    setConfirmationMessage(`Delete Node: ${flowData.nodes.find(node => node.id === id).data.label}? This cannot be undone.`);
    setOnConfirmAction(() => () => {
      setFlowData((prev) => ({
        ...prev,
        nodes: prev.nodes.filter((node) => node.id !== id),
        edges: prev.edges.filter((edge) => edge.source !== id && edge.target !== id),
      }));
      setSelectedNode(null);
      setDrawerOpen(false);
      setIsConfirmationOpen(false);
    });
    setIsConfirmationOpen(true);
  }, [flowData]);

  // Add this function to determine if a node is selected
  const isNodeSelected = useCallback((nodeId) => {
    return selectedNode && selectedNode.id === nodeId;
  }, [selectedNode]);

  // Modify the nodes to include the selected state
  const nodesWithSelectedState = useMemo(() => {
    return flowData.nodes.map(node => ({
      ...node,
      data: {
        ...node.data,
        selected: isNodeSelected(node.id),
      },
    }));
  }, [flowData.nodes, isNodeSelected]);

  const getLayoutedElements = useCallback((nodes, edges, direction = 'LR') => {
    if (!nodes.length) return { nodes: [], edges };

    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));

    const isHorizontal = direction === 'LR';
    dagreGraph.setGraph({ rankdir: direction });

    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    const layoutedNodes = nodes.map((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      return {
        ...node,
        targetPosition: isHorizontal ? 'left' : 'top',
        sourcePosition: isHorizontal ? 'right' : 'bottom',
        position: {
          x: nodeWithPosition.x - nodeWidth / 2,
          y: nodeWithPosition.y - nodeHeight / 2,
        },
      };
    });

    return { nodes: layoutedNodes, edges };
  }, []);

  const handleFileUpload = useCallback((event) => {
    const file = event.target.files[0];
    if (file) {
      const fileName = file.name.replace(/\.json$/, '');
      setUploadedFileName(fileName);
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const json = JSON.parse(e.target.result);
          console.log('Parsed JSON:', json);
          if (json.nodes && json.edges) {
            // Set workflow metadata
            if (json.metadata) {
              setWorkflowMetadata({
                ...json.metadata,
                title: json.metadata.title || fileName
              });
            } else {
              setWorkflowMetadata({ title: fileName, description: 'No description available.' });
            }

            // Process nodes
            const modifiedNodes = json.nodes.map(node => ({
              ...node,
              type: 'custom',
              position: node.position,
              width: node.width,
              height: node.height,
              data: {
                ...node.data,
                label: node.data.label || 'Untitled',
                description: node.data.description || 'No description available.',
                type: node.data.type || 'markdown',
                isAI: node.data.isAI || false,
                isLocked: node.data.isLocked || false,
                backgroundColor: node.data.backgroundColor || '#FFFFFF',
                fontColor: node.data.fontColor || '#000000',
                borderStyle: node.data.borderStyle || 'solid',
                width: node.data.width,
                height: node.data.height,
                onChange: handleNodeChange,
                onNodeClick: handleNodeClick,
                onResize: handleNodeResize
              }
            }));

            // Process edges
            const modifiedEdges = json.edges.map(edge => ({
              ...edge,
              type: 'custom',
              style: {
                stroke: edge.style?.stroke || '#000000',
                strokeWidth: edge.style?.strokeWidth || 1,
                strokeDasharray: edge.style?.strokeDasharray || null
              },
              markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 20,
                height: 20,
                color: edge.markerEnd?.color || '#000000'
              }
            }));

            setFlowData({ nodes: modifiedNodes, edges: modifiedEdges });

            setTimeout(() => {
              fitView({ padding: 0.2 });
            }, 0);
          } else {
            alert('Invalid JSON structure. Please make sure it contains "nodes" and "edges" arrays.');
          }
        } catch (error) {
          console.error('Error parsing JSON:', error);
          alert('Error parsing JSON file. Please make sure it\'s a valid JSON.');
        }
      };
      reader.readAsText(file);
    }
  }, [getLayoutedElements, fitView, handleNodeChange, handleNodeClick, handleNodeResize]);

  useEffect(() => {
    console.log('Flow data updated:', flowData);
  }, [flowData]);

  // useEffect for handling URL parameter
  useEffect(() => {
    const jsonUrl = searchParams.get('url');
    if (jsonUrl) {
      fetch(jsonUrl)
        .then(response => response.json())
        .then(json => {
          console.log('Fetched JSON:', json);
          if (json.nodes && json.edges) {
            const fileName = new URL(jsonUrl).pathname.split('/').pop().replace(/\.json$/, '');
            setUploadedFileName(fileName);

            if (json.metadata) {
              setWorkflowMetadata({
                ...json.metadata,
                title: json.metadata.title || fileName
              });
            } else {
              setWorkflowMetadata({ title: fileName, description: 'No description available.' });
            }

            const modifiedNodes = json.nodes.map(node => ({
              ...node,
              type: 'custom',
              position: node.position,
              data: {
                ...node.data,
                description: node.data.description || 'No description available.',
                onChange: handleNodeChange,
                onNodeClick: handleNodeClick,
              }
            }));

            const modifiedEdges = json.edges.map(edge => ({
              ...edge,
              markerEnd: { type: MarkerType.ArrowClosed },
            }));

            setFlowData({ nodes: modifiedNodes, edges: modifiedEdges });

            setTimeout(() => {
              fitView({ padding: 0.2 });
            }, 0);
          } else {
            alert('Invalid JSON structure. Please make sure it contains "nodes" and "edges" arrays.');
          }
        })
        .catch(error => {
          console.error('Error fetching or parsing JSON:', error);
          alert('Error loading JSON file. Please make sure the URL is correct and accessible.');
        });
    }
  }, [searchParams, fitView, handleNodeChange, handleNodeClick]);

  const fetchNotionPageContent = useCallback(async (pageId) => {
    try {
      const response = await fetch(`/api/notion/page-content/${pageId}`, {
        credentials: 'include'
      });
      const data = await response.json();
      return data.markdown; // We'll now receive markdown directly from the backend
    } catch (error) {
      console.error('Error fetching Notion page content:', error);
      return null;
    }
  }, []);

  const addNewNode = useCallback(async (label, description = 'Add your content here', type = 'html', notionPageId = null) => {
    let nodeContent = description;
    let nodeType = type;

    if (notionPageId) {
      const markdown = await fetchNotionPageContent(notionPageId);
      if (markdown) {
        nodeContent = markdown;
        nodeType = 'markdown';
      }
    }

    // Get the current viewport center and add a random x and y offset
    const viewportCenter = project({ x: window.innerWidth / 2 + Math.random() * 100, y: window.innerHeight / 2 + Math.random() * 100 });

    const newNode = {
      id: shortUuid(),
      type: 'custom',
      position: viewportCenter,
      data: {
        label,
        description: nodeContent,
        type: nodeType,
        isAI: type === 'ai',
        isLocked: false,  // Initialize isLocked property
        initialWidth: 172,
        initialHeight: 36,
        onChange: handleNodeChange,
        onNodeClick: handleNodeClick,
        onResize: handleNodeResize,
      },
    };

    console.log("Adding new node:", newNode);

    setFlowData((prev) => ({
      ...prev,
      nodes: [...prev.nodes, newNode],
    }));

    setIsAddNodeMenuOpen(false);
  }, [fetchNotionPageContent, handleNodeChange, handleNodeClick, project, handleNodeResize]);

  const deleteSelectedNode = useCallback(() => {
    if (selectedNode) {
      // Dismiss any open color pickers
      setColorPickerNode(null);
      setFontColorPickerNode(null);

      setConfirmationMessage(`Delete Node: ${selectedNode.label}? This cannot be undone.`);
      setOnConfirmAction(() => () => {
        setFlowData((prev) => ({
          ...prev,
          nodes: prev.nodes.filter((node) => node.id !== selectedNode.id),
          edges: prev.edges.filter((edge) => edge.source !== selectedNode.id && edge.target !== selectedNode.id),
        }));
        setSelectedNode(null);
        setDrawerOpen(false);
        setIsConfirmationOpen(false);
      });
      setIsConfirmationOpen(true);
    }
  }, [selectedNode]);

  // Handle JSON export
  const handleExport = useCallback(() => {
    const exportData = {
      metadata: {
        title: workflowMetadata.title,
        description: workflowMetadata.description
      },
      nodes: flowData.nodes.map(({ id, type, position, width, height, data }) => ({
        id,
        type: 'custom', // All nodes use our custom type
        position,
        width,
        height,
        data: {
          label: data.label,
          description: data.description,
          type: data.type || 'markdown', // Default to markdown if not specified
          isAI: data.isAI || false,
          isLocked: data.isLocked || false,
          backgroundColor: data.backgroundColor || '#FFFFFF',
          fontColor: data.fontColor || '#000000',
          borderStyle: data.borderStyle || 'solid',
          width: data.width,
          height: data.height
        }
      })),
      edges: flowData.edges.map(edge => ({
        id: edge.id,
        source: edge.source,
        target: edge.target,
        type: 'custom',
        style: {
          stroke: edge.style?.stroke || '#000000',
          strokeWidth: edge.style?.strokeWidth || 1,
          strokeDasharray: edge.style?.strokeDasharray || null
        },
        markerEnd: {
          type: MarkerType.ArrowClosed,
          width: 20,
          height: 20,
          color: edge.markerEnd?.color || '#000000'
        }
      }))
    };
  
    // Generate timestamp for the filename using local time
    const now = new Date();
    const timestamp = now.getFullYear() +
      '-' + String(now.getMonth() + 1).padStart(2, '0') +
      '-' + String(now.getDate()).padStart(2, '0') +
      '-' + String(now.getHours()).padStart(2, '0') +
      '-' + String(now.getMinutes()).padStart(2, '0') +
      '-' + String(now.getSeconds()).padStart(2, '0');
    
    // Use the uploaded filename if available, otherwise use 'Flow'
    let baseFileName = uploadedFileName || 'Flow';

    // Remove any existing timestamp from the baseFileName
    baseFileName = baseFileName.replace(/-\d{4}-\d{2}-\d{2}-\d{2}-\d{2}-\d{2}$/, '');

    const filename = `${baseFileName}-${timestamp}.json`;
  
    const blob = new Blob([JSON.stringify(exportData, null, 2)], { type: 'application/json' });
    saveAs(blob, filename);
  }, [flowData, uploadedFileName, workflowMetadata]);

  const onEdgeUpdate = useCallback((oldEdge, newConnection) => {
    setFlowData((prev) => ({
      ...prev,
      edges: prev.edges.map((edge) => {
        if (edge.id === oldEdge.id) {
          return { ...edge, ...newConnection };
        }
        return edge;
      }),
    }));
  }, []);

  const onEdgeClick = useCallback((event, edge) => {
    event.stopPropagation();
    setSelectedEdge(edge);
    setFlowData((prev) => ({
      ...prev,
      edges: prev.edges.map((e) => ({
        ...e,
        style: {
          ...e.style,
          stroke: e.id === edge.id ? '#FF0000' : '#000000',
          strokeWidth: 1,
        },
        markerEnd: {
          ...e.markerEnd,
          color: e.id === edge.id ? '#FF0000' : '#000000',
        },
        data: { ...e.data, isSelected: e.id === edge.id },
      })),
    }));
  }, []);

  const toggleEdgeStyle = useCallback(() => {
    if (selectedEdge) {
      setFlowData((prev) => ({
        ...prev,
        edges: prev.edges.map((e) => {
          if (e.id === selectedEdge.id) {
            return {
              ...e,
              style: {
                ...e.style,
                strokeDasharray: e.style.strokeDasharray ? null : '5, 5',
              },
            };
          }
          return e;
        }),
      }));
    }
  }, [selectedEdge]);

  // Handle edge deletion
  const deleteSelectedEdge = useCallback(() => {
    const selectedEdge = flowData.edges.find(edge => edge.data?.isSelected);
    if (selectedEdge) {
      setConfirmationMessage('Are you sure you want to delete this edge?');
      setOnConfirmAction(() => () => {
        setFlowData((prev) => ({
          ...prev,
          edges: prev.edges.filter((e) => e.id !== selectedEdge.id),
        }));
        setIsConfirmationOpen(false);
      });
      setIsConfirmationOpen(true);
    }
  }, [flowData.edges]);

  useEffect(() => {
    if (accessToken) {
      fetchNotionPages();
    }
  }, [accessToken]);

  const fetchNotionPages = useCallback(() => {
    fetch('/api/notion/pages', {
      credentials: 'include'
    })
      .then(response => response.json())
      .then(data => {
        if (data.pages) {
          setNotionPages(data.pages);
        }
      })
      .catch(error => console.error('Error:', error));
  }, []);

  const filteredNotionPages = useMemo(() => {
    return notionPages.filter(page => 
      page.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [notionPages, searchQuery]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (addNodeMenuRef.current && !addNodeMenuRef.current.contains(event.target)) {
        setIsAddNodeMenuOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLockChange = useCallback((nodeId, isLocked) => {
    console.log('handleLockChange called:', nodeId, isLocked);  // Debug log
    
    setFlowData(prev => {
      const newNodes = prev.nodes.map(node => {
        if (node.id === nodeId) {
          console.log('Updating node:', node.id, 'isLocked:', isLocked);  // Debug log
          return {
            ...node,
            data: {
              ...node.data,
              isLocked: isLocked
            }
          };
        }
        return node;
      });
      
      return {
        ...prev,
        nodes: newNodes
      };
    });
  }, []);

  // Add this function near the other button handlers
  const handleAutoLayout = useCallback(() => {
    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      flowData.nodes,
      flowData.edges,
      'LR' // Left to Right direction
    );

    setFlowData({
      nodes: layoutedNodes,
      edges: layoutedEdges,
    });

    // Add a small delay before fitting the view
    setTimeout(() => {
      fitView({ padding: 0.2 });
    }, 50);
  }, [flowData, getLayoutedElements, fitView]);

  // Add this useEffect to handle clicks outside the menu
  useEffect(() => {
    function handleClickOutside(event) {
      if (submenuRef.current && !submenuRef.current.contains(event.target)) {
        setIsAddNodeSubMenuOpen(false);
      }
    }

    // Only add the listener when the menu is open
    if (isAddNodeSubMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isAddNodeSubMenuOpen]);

  // Add this useEffect for handling clicks outside
  useEffect(() => {
    const handleClick = (e) => {
      // If menu is open and click is outside menu container
      if (menuState.isOpen && menuRef.current && !menuRef.current.contains(e.target)) {
        setMenuState({ isOpen: false, type: null });
      }
    };

    // Add event listener when menu is open
    if (menuState.isOpen) {
      document.addEventListener('mousedown', handleClick);
    }

    // Cleanup
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [menuState.isOpen]);

  // Modify the addNewNode function to handle Notion page selection
  const handleNotionPageSelect = async (page) => {
    await addNewNode(page.title, 'Loading content...', 'markdown', page.id);
    setIsNotionModalOpen(false);
  };

  // Wrap the return statement with the context provider
  return (
    <FlowContext.Provider value={{
      setFlowData,
      setWorkflowMetadata,
      handleNodeChange,
      handleNodeClick,
      handleNodeResize
    }}>
      <div style={{ width: '100vw', height: '100vh' }} ref={reactFlowWrapper}>
        <div style={{ 
          padding: '10px', 
          display: 'flex', 
          alignItems: 'center',
          height: '60px',
          position: 'relative',
          justifyContent: 'space-between',
        }}>
          {/* Left section */}
          <div style={{ 
            display: 'flex', 
            alignItems: 'center',
            width: '33%',
          }}>
            {/* Replace the existing file input with this */}
            <label className="custom-file-upload desktop-file-upload">
              <input 
                type="file" 
                accept=".json" 
                onChange={handleFileUpload}
              />
              <span className="custom-file-label">
                Upload JSON
              </span>
            </label>
          </div>

          {/* Center section - Title */}
          <h1 
            style={{ 
              cursor: 'pointer', 
              textAlign: 'center',
              margin: '0',
              fontSize: window.innerWidth <= 768 ? '1.2em' : '1.5em',
              flex: '1',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              padding: '0 10px',
            }} 
            onClick={() => setIsModalOpen(true)}
          >
            {workflowMetadata.title || 'Untitled Workflow'}
          </h1>

          {/* Right section */}
          <div style={{ 
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '33%',
          }}>
            {window.innerWidth <= 768 ? (
              <HamburgerMenu 
                onClick={() => setIsMenuOpen(!isMenuOpen)} 
                isOpen={isMenuOpen}
              />
            ) : (
              <div style={{ display: 'flex', gap: '10px' }}>
                {/* Original menu items */}
                <button onClick={handleExport} className="menu-button">Export</button>
                <button onClick={handleAutoLayout} className="menu-button">Auto-Layout</button>
                
                {/* Desktop Add Node dropdown */}
                <div 
                  className="desktop-submenu-container" 
                  ref={submenuRef}
                >
                  <button 
                    onClick={() => setIsAddNodeSubMenuOpen(!isAddNodeSubMenuOpen)}
                    className="menu-button with-arrow"
                  >
                    Add Node {isAddNodeSubMenuOpen ? '▾' : '▾'}
                  </button>
                  
                  {isAddNodeSubMenuOpen && (
                    <div 
                      className="desktop-submenu"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <button 
                        onClick={() => {
                          addNewNode('New Node', 'Add your content here', 'markdown');
                          setIsAddNodeSubMenuOpen(false);
                        }}
                        className="desktop-submenu-item"
                      >
                        Add Basic Node
                      </button>
                      <button 
                        onClick={() => {
                          addNewNode('AI Node', 'This node will be updated by AI', 'ai');
                          setIsAddNodeSubMenuOpen(false);
                        }}
                        className="desktop-submenu-item"
                      >
                        Add AI Node
                      </button>
                      <button
                        onClick={() => {
                          setIsNotionModalOpen(true);
                          setIsAddNodeSubMenuOpen(false);
                        }}
                        className="desktop-submenu-item"
                      >
                        Import from Notion
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          {/* Mobile menu overlay */}
          {window.innerWidth <= 768 && isMenuOpen && (
            <div style={{
              position: 'absolute',
              top: '60px',
              right: '0',
              backgroundColor: 'white',
              boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
              borderRadius: '4px',
              padding: '10px',
              zIndex: 1001,
              minWidth: '200px', // Add minimum width to ensure sub-menu fits
            }}>
              <label className="custom-file-upload">
                <input 
                  type="file" 
                  accept=".json" 
                  onChange={handleFileUpload}
                />
                <span className="mobile-menu-item">
                  Upload JSON
                </span>
              </label>
              {!accessToken ? (
                <a href={notionAuthUrl} className="mobile-menu-item">Log in to Notion</a>
              ) : (
                <span className="mobile-menu-item">Logged In</span>
              )}
              <button onClick={handleExport} className="mobile-menu-item">Export</button>
              <button onClick={handleAutoLayout} className="mobile-menu-item">Auto-Layout</button>
              
              {/* Add Node with sub-menu */}
              <div className="mobile-submenu-container">
                <button 
                  onClick={() => setIsAddNodeSubMenuOpen(!isAddNodeSubMenuOpen)}
                  className="mobile-menu-item with-arrow"
                >
                  Add Node {isAddNodeSubMenuOpen ? '▾' : '▸'}
                </button>
                
                {isAddNodeSubMenuOpen && (
                  <div className="mobile-submenu">
                    <button 
                      onClick={() => {
                        addNewNode('New Node', 'Add your content here', 'markdown');
                        setIsMenuOpen(false);
                        setIsAddNodeSubMenuOpen(false);
                      }}
                      className="mobile-submenu-item"
                    >
                      Add Basic Node
                    </button>
                    <button 
                      onClick={() => {
                        addNewNode('AI Node', 'This node will be updated by AI', 'ai');
                        setIsMenuOpen(false);
                        setIsAddNodeSubMenuOpen(false);
                      }}
                      className="mobile-submenu-item"
                    >
                      Add AI Node
                    </button>
                    <button
                      onClick={() => {
                        setIsNotionModalOpen(true);
                        setIsMenuOpen(false);
                        setIsAddNodeSubMenuOpen(false);
                      }}
                      className="mobile-submenu-item"
                    >
                      Import from Notion
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <ReactFlow
          nodes={flowData.nodes.map(node => {
            console.log('Node mapping:', node.id, node.data.isLocked);  // Debug log
            return {
              ...node,
              data: {
                ...node.data,
                onChange: handleNodeChange,
                onNodeClick: handleNodeClick,
                onLockChange: handleLockChange,
                onToggleColorPicker: handleToggleColorPicker,
                onToggleFontColorPicker: handleToggleFontColorPicker, // Fixed: Add proper reference
                onToggleBorderStyle: handleToggleBorderStyle,
                deleteSelectedNode: deleteSelectedNode,
                isSelected: node.id === selectedNode?.id,
                isColorPickerActive: colorPickerNode?.id === node.id,
                isFontColorPickerActive: fontColorPickerNode?.id === node.id,
              }
            };
          })}
          edges={flowData.edges.map(edge => ({
            ...edge,
            type: 'custom',
            data: {
              ...edge.data,
              isSelected: edge.data?.isSelected,
              onDelete: deleteSelectedEdge,
              onToggleStyle: () => toggleEdgeStyle(), // Add this line
            }
          }))}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onEdgeUpdate={onEdgeUpdate}
          onEdgeClick={onEdgeClick}
          onConnect={onConnect}
          onPaneClick={onPaneClick}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          fitView
          selectNodesOnDrag={false}
        >
          <Controls />
            <Background />
          {/* <CustomBackground /> */}
        </ReactFlow>

        {/* Node Position Debugging Info */}
        {selectedNode && (
          <div
            style={{
              position: 'absolute',
              bottom: '10px',
              right: '10px',
              textAlign: 'right',
              background: 'rgba(255, 255, 255, 0.8)',
              padding: '10px',
              borderRadius: '5px',
              fontSize: '12px',
              color: '#919191',
              zIndex: 1000,
            }}
          >
            <div>{selectedNode.label}</div>
            <div>ID:{selectedNode.id}</div>
            <div>X: {selectedNodePosition.x}</div>
            <div>Y: {selectedNodePosition.y}</div>
          </div>
        )}

        <SideDrawer
          isOpen={drawerOpen}
          onClose={() => {
            setDrawerOpen(false);
            setSelectedNode(null);
            // Deselect all nodes when closing the drawer
            setFlowData(prev => ({
              ...prev,
              nodes: prev.nodes.map(node => ({
                ...node,
                data: {
                  ...node.data,
                  isSelected: false
                }
              }))
            }));
          }}
          node={selectedNode}
          onNodeChange={handleNodeChange}
        />
        <WorkflowInfoModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title={workflowMetadata.title}
          description={workflowMetadata.description}
          onSave={handleSaveWorkflowMetadata}
        />
        <ConfirmationModal
          isOpen={isConfirmationOpen}
          message={confirmationMessage}
          onConfirm={onConfirmAction}
          onCancel={() => setIsConfirmationOpen(false)}
        />
        {colorPickerNode && (
          <div 
            ref={colorPickerRef}
            style={{
              position: 'fixed',
              zIndex: 2000,
              top: `${colorPickerPosition.y}px`,
              left: `${colorPickerPosition.x}px`,
              transform: 'translateX(-50%)',
            }}
          >
            <SketchPicker
              color={flowData.nodes.find(n => n.id === colorPickerNode.id)?.data.backgroundColor || '#FFFFFF'}
              onChange={handleColorChange}
              onChangeComplete={handleColorChangeComplete}
              presetColors={recentColors}
            />
          </div>
        )}
        {fontColorPickerNode && (
          <div 
            ref={fontColorPickerRef}
            style={{
              position: 'fixed',
              zIndex: 2000,
              top: `${fontColorPickerPosition.y}px`,
              left: `${fontColorPickerPosition.x}px`,
              transform: 'translateX(-50%)',
            }}
          >
            <SketchPicker
              color={flowData.nodes.find(n => n.id === fontColorPickerNode.id)?.data.fontColor || '#000000'}
              onChange={handleFontColorChange}
              onChangeComplete={handleFontColorChange}
              presetColors={recentColors}
            />
          </div>
        )}
        <MorphingButton />
        {/* Add this before the closing FlowContext.Provider tag */}
        <NotionImportModal
          isOpen={isNotionModalOpen}
          onClose={() => setIsNotionModalOpen(false)}
          notionPages={notionPages}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onSelectPage={handleNotionPageSelect}
          accessToken={accessToken}
          notionAuthUrl={notionAuthUrl}
        />
      </div>
    </FlowContext.Provider>
  );
}

const SideDrawer = ({ isOpen, onClose, node, onNodeChange }) => {
  // Calculate initial drawer width based on screen size
  const getInitialDrawerWidth = () => {
    // You can adjust these breakpoints as needed
    const isMobileDevice = window.innerWidth <= 768; // Common breakpoint for mobile devices
    return isMobileDevice ? '95vw' : '33.33vw';
  };

  const [drawerWidth, setDrawerWidth] = useState(getInitialDrawerWidth());

  // Add useEffect to update drawer width when window is resized
  useEffect(() => {
    const handleResize = () => {
      setDrawerWidth(getInitialDrawerWidth());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Reset drawer width when it's opened
  useEffect(() => {
    if (isOpen) {
      setDrawerWidth(getInitialDrawerWidth());
    }
  }, [isOpen]);

  const [isEditing, setIsEditing] = useState(false);
  const [editedDescription, setEditedDescription] = useState('');
  const [originalDescription, setOriginalDescription] = useState('');
  const [editedLabel, setEditedLabel] = useState('');
  const [originalLabel, setOriginalLabel] = useState('');
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [onConfirmAction, setOnConfirmAction] = useState(() => () => {});

  useEffect(() => {
    if (node) {
      setEditedDescription(node.description || '');
      setOriginalDescription(node.description || '');
      setEditedLabel(node.label || '');
      setOriginalLabel(node.label || '');
    }
  }, [node]);

  const handleSave = () => {
    onNodeChange(node.id, { 
      description: editedDescription,
      label: editedLabel
    });
    setIsEditing(false);
    setOriginalDescription(editedDescription);
    setOriginalLabel(editedLabel);
  };

  const handleClose = () => {
    if (isEditing && (editedDescription !== originalDescription || editedLabel !== originalLabel)) {
      setConfirmationMessage("Discard your edits?");
      setOnConfirmAction(() => () => {
        setIsEditing(false);
        setEditedDescription(originalDescription);
        setEditedLabel(originalLabel);
        onClose();
        setIsConfirmationOpen(false);
      });
      setIsConfirmationOpen(true);
    } else {
      setIsEditing(false);
      onClose();
    }
  };

  const handleStart = (e) => {
    e.preventDefault();
    // Handle both mouse and touch events
    document.addEventListener('mousemove', handleMove);
    document.addEventListener('mouseup', handleEnd);
    document.addEventListener('touchmove', handleMove);
    document.addEventListener('touchend', handleEnd);
  };

  const handleMove = (e) => {
    const clientX = e.touches ? e.touches[0].clientX : e.clientX;
    // Adjust minimum width based on screen size
    const minWidth = window.innerWidth <= 768 ? window.innerWidth * 0.5 : window.innerWidth * 0.33;
    const newWidth = Math.min(Math.max(clientX, minWidth), window.innerWidth * 0.95);
    setDrawerWidth(`${newWidth}px`);
  };

  const handleEnd = () => {
    // Remove both mouse and touch event listeners
    document.removeEventListener('mousemove', handleMove);
    document.removeEventListener('mouseup', handleEnd);
    document.removeEventListener('touchmove', handleMove);
    document.removeEventListener('touchend', handleEnd);
  };

  const quillModules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
      ['clean'],
    ],
    imageDrop: true,
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize', 'Toolbar'],
      handleStyles: {
        backgroundColor: 'black',
        border: 'none',
        color: 'white'
      }
    }
  };

  const quillFormats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'blockquote', 'code-block',
    'list', 'bullet',
    'link', 'image',
    'width', 'height', 'style' // Add these formats to preserve image size
  ];

  const handleImageUpload = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };
  const handleQuillChange = (content, delta, source, editor) => {
    const htmlContent = editor.getHTML();
    setEditedDescription(htmlContent);
  };

  console.log("Node data:", node?.data);
  console.log("Node type:", node?.data?.type);

  const nodeType = node?.data?.type || node?.type || 'html';

  return (
    <div 
      className={`side-drawer ${isOpen ? 'open' : ''}`}
      style={{
        position: 'fixed',
        top: 0,
        left: isOpen ? 0 : `-${drawerWidth}`,
        width: drawerWidth,
        height: '100vh',
        backgroundColor: 'white',
        boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)',
        transition: 'left 0.3s ease-in-out',
        zIndex: 1000,
        padding: '20px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        borderRight: '1px solid #CCCCCC',
      }}
    >
      <div 
        style={{
          width: '6px',
          height: '40px',
          cursor: 'ew-resize',
          position: 'absolute',
          top: '50%',
          right: '-8px',
          transform: 'translateY(-50%)',
          zIndex: 1001,
          backgroundColor: 'white',
          border: '1px solid #CCCCCC',
          borderLeft: '1px solid white',
          borderRadius: '0 10px 10px 0',
          boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)',
          touchAction: 'none', // Prevent default touch actions
        }}
        onMouseDown={handleStart}
        onTouchStart={handleStart}
      />
      {/* Remove the close button from here */}
      {node && (
        <>
          {/* Modify this div to include both edit controls and close button */}
          <div style={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            marginBottom: '20px',
          }}>
            <div>
            {!isEditing && (
                <button
                  onClick={() => setIsEditing(!isEditing)}
                  style={{
                    background: 'none',
                    border: 'none',
                    fontSize: '18px',
                    cursor: 'pointer',
                    marginRight: '10px',
                  }}
                >
                  ✎
                </button>
              )}
              {isEditing && (
                <button
                  onClick={handleSave}
                  style={{
                    background: 'none',
                    border: 'none',
                    fontSize: '18px',
                    cursor: 'pointer',
                  }}
                >
                  💾
                </button>
              )}
            </div>
            {/* Add the close button here */}
            <button 
              onClick={handleClose}
              style={{
                background: 'none',
                border: 'none',
                fontSize: '24px',
                cursor: 'pointer',
              }}
            >
              ×
            </button>
          </div>
          {isEditing ? (
            <>
              <input
                type="text"
                value={editedLabel}
                onChange={(e) => setEditedLabel(e.target.value)}
                style={{
                  fontSize: '24px',
                  fontWeight: 'bold',
                  marginBottom: '10px',
                  width: '100%',
                  padding: '5px',
                }}
              />
              {nodeType === 'markdown' || nodeType === 'ai' ? (
                <MDEditor
                  value={editedDescription}
                  onChange={setEditedDescription}
                  height="calc(100% - 60px)"
                  data-color-mode="light"
                  preview="edit"
                />
              ) : (
                <ReactQuill
                  value={editedDescription}
                  onChange={handleQuillChange}
                  modules={quillModules}
                  formats={quillFormats}
                  style={{ height: 'calc(100% - 60px)' }}
                />
              )}
            </>
          ) : (
            <>
              <h2>{node.label}</h2>
              {nodeType === 'markdown' || nodeType === 'ai' ? (
                <div
                  style={{
                    maxWidth: '100%',
                    overflowY: 'auto',
                    height: 'calc(100% - 60px)',
                  }}
                >
                  <ReactMarkdown>{node.description}</ReactMarkdown>
                </div>
              ) : (
                <div 
                  dangerouslySetInnerHTML={{ __html: node.description }}
                  className="rendered-content"
                  style={{
                    maxWidth: '100%',
                    overflowX: 'auto',
                  }}
                />
              )}
            </>
          )}
        </>
      )}
      <ConfirmationModal
        isOpen={isConfirmationOpen}
        message={confirmationMessage}
        onConfirm={onConfirmAction}
        onCancel={() => setIsConfirmationOpen(false)}
      />
    </div>
  );
};

// Add this new component for the color wheel icon
const ColorWheelIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 540 540"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m 511.48146,205.29524 c 11.90567,44.43259 11.90567,84.97693 0,129.40952 L 487.31476,349.3424 270,270 490.20449,192.09986 z" fill="#fefe33" />
    <path d="m 334.70476,28.518543 c 44.43259,11.905676 79.54502,32.17785 112.07193,64.704761 L 447.40047,118.09589 270,270 313.06484,40.508134 z" fill="#fb9902" />
    <path d="m 446.77669,93.223304 c 32.52692,32.526916 52.79909,67.639346 64.70477,112.071936 L 270,270 z" fill="#fabc02" />
    <path d="M 93.223305,93.223305 C 125.75022,60.696393 160.86265,40.42422 205.29524,28.518543 L 231.20546,44.501656 270,270 92.739568,120.0571 z" fill="#fe2712" />
    <path d="m 205.29524,28.518543 c 44.43259,-11.905676 84.97693,-11.905676 129.40952,0 L 270,270 z" fill="#fd5308" />
    <path d="m 28.518543,334.70476 c -11.905676,-44.43259 -11.905676,-84.97693 0,-129.40952 L 56.311276,186.62718 270,270 55.854788,349.40527 z" fill="#8601af" />
    <path d="M 28.518543,205.29524 C 40.424219,160.86265 60.696393,125.75022 93.223305,93.223305 L 270,270 z" fill="#a7194b" />
    <path d="M 205.29524,511.48146 C 160.86265,499.57578 125.75022,479.30361 93.223305,446.7767 L 95.307837,418.58874 270,270 231.0453,499.70648 z" fill="#0247fe" />
    <path d="M 93.223305,446.7767 C 60.696393,414.24978 40.42422,379.13735 28.518543,334.70476 L 270,270 z" fill="#3d01a4" />
    <path d="m 446.7767,446.7767 c -32.52692,32.52691 -67.63935,52.79908 -112.07194,64.70476 L 310.45335,496.38826 270,270 446.04632,421.15701 z" fill="#66b032" />
    <path d="m 334.70476,511.48146 c -44.43259,11.90567 -84.97693,11.90567 -129.40952,0 L 270,270 z" fill="#0391ce" />
    <path d="M 511.48146,334.70476 C 499.57578,379.13735 479.30361,414.24978 446.7767,446.7767 L 270,270 511.48146,334.70476 z" fill="#d0ea2b" />
    <circle cx="270" cy="270" r="153.79581" fill="#ffffff" />
  </svg>
);

// Add this new component for the custom background
const CustomBackground = () => {
  const { width, height } = useReactFlow().getViewport();
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: 'url(/bg_image_001.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        opacity: 0.5, // Adjust this value to make the image more or less transparent
        pointerEvents: 'none', // This ensures the background doesn't interfere with interactions
      }}
    />
  );
};

// Add this new component for the custom edge
const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data,
}) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      {data?.isSelected && (
        <EdgeLabelRenderer>
          <div
            style={{
              position: 'absolute',
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              fontSize: 16,
              backgroundColor: 'white',
              padding: '4px',
              borderRadius: '30px',
              border: '1px solid #FF0000',
              cursor: 'pointer',
              pointerEvents: 'all',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '4px',
            }}
            className="nodrag nopan"
          >
            <button
              onClick={(event) => {
                event.stopPropagation();
                data.onDelete(id);
              }}
              style={{
                background: 'none',
                border: '1px solid #FF0000',
                borderRadius: '50%',
                padding: 0,
                width: '20px',
                height: '20px',
                cursor: 'pointer',
                color: '#FF0000',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                lineHeight: '1',
              }}
              title="Delete edge"
            >
              ×
            </button>
            <button
              onClick={(event) => {
                event.stopPropagation();
                data.onToggleStyle();
              }}
              style={{
                background: 'none',
                border: '1px dashed black',
                borderRadius: '50%',
                padding: 0,
                width: '20px',
                height: '20px',
                cursor: 'pointer',
                color: '#666',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                lineHeight: '1',
                fontSize: '14px',
              }}
              title="Toggle line style"
            >
              
            </button>
          </div>
        </EdgeLabelRenderer>
      )}
    </>
  );
};

// Add CustomEdge to edge types
const edgeTypes = {
  custom: CustomEdge,
};

// Add this new component near the top of the file
const NotionImportModal = ({ isOpen, onClose, notionPages, searchQuery, setSearchQuery, onSelectPage, accessToken, notionAuthUrl }) => {
  if (!isOpen) return null;

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 2000,
    }}>
      <div style={{
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '5px',
        maxWidth: '500px',
        width: '90%',
        height: '500px',
        display: 'flex',
        flexDirection: 'column',
      }}>
        <h2 style={{ margin: '0 0 15px 0' }}>Import from Notion</h2>
        
        {!accessToken ? (
          <div style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '20px',
          }}>
            <p style={{ textAlign: 'center', color: '#666' }}>
              Connect your Notion account to import pages as nodes.
            </p>
            <a 
              href={notionAuthUrl}
              style={{
                padding: '12px 24px',
                backgroundColor: '#000',
                color: '#fff',
                textDecoration: 'none',
                borderRadius: '4px',
                display: 'inline-flex',
                alignItems: 'center',
                gap: '8px',
                transition: 'background-color 0.2s',
              }}
              onMouseOver={(e) => e.target.style.backgroundColor = '#333'}
              onMouseOut={(e) => e.target.style.backgroundColor = '#000'}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 4h16v16H4V4z" fill="#fff"/>
                <path d="M7 7h10v10H7V7z" fill="#000"/>
              </svg>
              Connect to Notion
            </a>
          </div>
        ) : (
          <>
            <input
              type="text"
              placeholder="Search pages..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{
                padding: '8px',
                marginBottom: '15px',
                border: '1px solid #ddd',
                borderRadius: '4px',
              }}
            />
            <div style={{
              flex: 1,
              overflowY: 'auto',
              borderTop: '1px solid #eee',
              borderBottom: '1px solid #eee',
              padding: '10px 0',
            }}>
              {notionPages
                .filter(page => page.title.toLowerCase().includes(searchQuery.toLowerCase()))
                .map(page => (
                  <button
                    key={page.id}
                    onClick={() => {
                      onSelectPage(page);
                      onClose();
                    }}
                    style={{
                      width: '100%',
                      padding: '10px',
                      margin: '5px 0',
                      textAlign: 'left',
                      background: 'none',
                      border: '1px solid #ddd',
                      borderRadius: '4px',
                      cursor: 'pointer',
                      transition: 'background-color 0.2s',
                    }}
                    onMouseOver={(e) => e.target.style.backgroundColor = '#f5f5f5'}
                    onMouseOut={(e) => e.target.style.backgroundColor = 'transparent'}
                  >
                    {page.title}
                  </button>
                ))}
            </div>
          </>
        )}
        
        <button
          onClick={onClose}
          style={{
            marginTop: '15px',
            padding: '8px',
            backgroundColor: '#f0f0f0',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            transition: 'background-color 0.2s',
          }}
          onMouseOver={(e) => e.target.style.backgroundColor = '#e0e0e0'}
          onMouseOut={(e) => e.target.style.backgroundColor = '#f0f0f0'}
        >
          Close
        </button>
      </div>
    </div>
  );
};

function App() {
  const [message, setMessage] = useState('');
  const [notionAuthUrl, setNotionAuthUrl] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [notionPages, setNotionPages] = useState([]);

  useEffect(() => {
    fetch('/api/hello')
      .then(response => response.json())
      .then(data => setMessage(data.message))
      .catch(error => console.error('Error:', error));

    fetch('/api/notion/auth')
      .then(response => response.json())
      .then(data => setNotionAuthUrl(data.auth_url))
      .catch(error => console.error('Error:', error));

    fetchNotionPages();
  }, []);

  const fetchNotionPages = () => {
    fetch('/api/notion/pages', {
      credentials: 'include'
    })
      .then(response => response.json())
      .then(data => {
        if (data.pages) {
          setNotionPages(data.pages);
          setAccessToken('token_exists');
        }
      })
      .catch(error => console.error('Error:', error));
  };

  return (
    <div className="App">
      <ReactFlowProvider>
        <FlowChart accessToken={accessToken} notionAuthUrl={notionAuthUrl} />
      </ReactFlowProvider>
    </div>
  );
}

export default App;

